// Generated by Bugpilot Wizard
"use client";

import { captureError } from "@bugpilot/plugin-nextjs";

import "@bugpilot/react-error-pages/dist/index.css";
import { BasicErrorPage } from "@bugpilot/react-error-pages";

// Global errors are rendered when an error is thrown outside of a page,
// or on the root layout. Unfortunately, there is currently no way to avoid rendering
// the global error page in development mode. The actual error is logged to the
// browser console.

function ErrorPage({
  error,
  reset,
}: React.PropsWithChildren<{
  error: Error & { digest?: string };
  reset: () => void;
}>) {
  const handleReportError = (error: Error) => {
    captureError(error, {
      kind: "error-page",
    });
  };

  // Global Error also catch root layout errors
  // so we need to render the html and body tags here
  return (
    <BasicErrorPage
      error={error}
      reset={reset}
      reportErrorFn={handleReportError}
    />
  );
}

export default process.env.NODE_ENV === "production" ? ErrorPage : undefined;
